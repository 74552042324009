import { SectionContent } from "components/common/sectionContent";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";

export const BackedBySection = () => (
  <section className="backedBy-section">
    <SectionContent maxWidthType="section-maxWidth-xxsm" className="flex flex-center flex-justify-spaceBetween">
      <h2 className="flex-1">Backed By</h2>
      <div className="logo-aquiline logo-margin" />
      <div className="logo-commerceVentures logo-margin" />
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
            <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
            <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
            <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
    </SectionContent>
  </section>
);
