import { SectionContent } from "components/common/sectionContent";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";

const FlueidDifferentSection = () => {
  return (
    <section className="sec-flueid-different-section">
      <SectionContent>
        <div className="main-text-content margin-bottom_lg">
          {/* KEEP COMMENTED OUT FOR NOW - this section already has animations, plus these won't fit nicely on mobile */}
          {/* <DashedLineGroup>
            {(visible) => (
              <>
                <DashedLine color="black" width={98} duration={2.1} delay={0} x={"4vw"} show={visible} />
                <DashedLine color="black" width={35} duration={1.3} delay={0.3} x={"9vw"} show={visible} />
                <DashedLine color="black" width={200} duration={0.9} delay={0.8} x={"12vw"} show={visible} />
              </>
            )}
          </DashedLineGroup> */}
          <h2 className="margin-none">What makes Flueid different?</h2>
          <p className="p txt-regular p-max-width-mobile">Security is Our Guide. Trust is Our Promise.</p>
        </div>
        <div className="main-section-content">
          <div className="quote-container flex-1">
            <div className="quote-dots" />
            <Visible>
              {({ visible, ref }) => (
                <Transition visible={visible} animation="fadeInLeft" delay={1000}>
                  <div className="quote-card">
                    <h1 className="background-quotes">“</h1>
                    <h3 className="main-quote" ref={ref}>
                      The report documents how Flueid safeguards customer data and highlights the shared values and
                      collaborative teamwork in pursuit of the organization’s goals and objectives to deliver a secure
                      platform.”
                    </h3>
                  </div>
                </Transition>
              )}
            </Visible>
          </div>
          <div className="security-guides">
            <h3>
              <b>Security Guides</b> Our Team
            </h3>
            <p className="p-1 margin-bottom_md">
              Uncompromising in our standards,{" "}
              <b>
                Flueid achieved the SSAE SOC 2 Type 1 and 2 certifications from a stringent industry-leading audit firm
                within 18 months of our platform’s launch.
              </b>
            </p>
            <p className="p-1 margin-bottom_md">
              <b>Flueid goes beyond the Standard Information Gathering (SIG) for institutional banks and lenders, </b>
              ranking us among the top in the industry.
            </p>
            <ul>
              <li className="p-1">
                ‘A’ cyber rating and 98% compliance rating from a top cyber risk intelligence platform
              </li>
              <li className="p-1">98% alignment with NIST CSF</li>
            </ul>
          </div>
          <DashedLineGroup animationDirection="left" className="dashedLineGroup-bottom">
            {(visible) => (
              <>
                <DashedLine color="black" width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                <DashedLine color="black" width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                <DashedLine color="black" width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                <DashedLine color="black" width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
              </>
            )}
          </DashedLineGroup>
        </div>
      </SectionContent>
    </section>
  );
};

export default FlueidDifferentSection;
